<template>
    <a class="bg-primary text-white px-10 py-4 rounded-full uppercase text-xs md:text-sm xl:text-lg font-bold font-display text-center" :class="class" :href="href">
        <slot />
    </a>
</template>

<script>

export default {
    props: {
        class: {
            type: String,
            default: "",
        },
        href: {
            type: String,
            default: undefined,
        }
    },
}
</script>